<template>
  <div class="detail-container">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="/" style="margin-right: 10px;">返回WorkData</a>

    </nav>

    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <img
            :src="items.logo"
            alt="软件logo"
            style="max-width:100%;  max-height: 140px;"
            id="head"
          />
        </div>
        <div class="col-md-10" style="margin-top: 10px; margin-bottom: 10px;">
          <h1 style="color:blue;" id="hname">{{ items.hname }}</h1>
          <div style="color:grey;" id="desc">{{ items.desc }}</div>
          <div
           
            id="tag1"
            class="detail_tag"
          >
            {{ items.tag1 }}
          </div>
          <div
            
            id="tag2"
            class="detail_tag"
          >
            {{ items.tag2 }}
          </div>
          <div>
            <button class="btn btn-primary" id="link1" @click="toPage(items.link)">
            链接直达
           </button>
           <button class="btn btn-primary" id="link2" @click.stop="DB_bu()">
            一键分享
           </button>
          </div>
          
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h2 style="font-size: 1.5em;" v-if="items.shot1 != 0">预览使用</h2>
          <!-- <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, idx) in items.shot1"
                :key="idx"
              >
                <img :src="item" alt="软件截图" class="img-fluid" id="shot1" style="width: 90%;margin: 20px 20px 20px 20px;"/>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div> -->
          <p>以下内容可能存在广告、营销等内容，并不代表workdata自身观点，请注意辨别</p>
       <Iframe :link = 'items.link' :if_Iframe="if_Iframe" v-if="if_Iframe"></Iframe>


        </div>
      </div>
    </div>

  </div>
</template>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?181b2d56cf41a8d4e9262a313c2bacbc";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import cloudbase from "@cloudbase/js-sdk";
import Iframe from '../../components/iframe.vue'
export default {
  name: "Index",
  components: {
    Iframe,
    
  },
  data() {
    return {
      items: [],
      id: "",
      info: {},
      if_Iframe:false,
      setting: {
        title: "111",
        keywords: "111",
        description: "111",
      },

    };
  },
  metaInfo() {
    return {
      title: this.items.hname,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  methods: {
    getInfo() {
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018", // 此处填入您的环境ID
      });
      let self = this;
      app
        .auth()
        .anonymousAuthProvider()
        .signIn()
        .then(() => {
          //huoqyu bid
          app
            .callFunction({
              // 云函数名称
              name: "hao",
              // 传给云函数的参数
              data: {
                type: "get_hdetail",
                hid: self.id,
              },
            })
            .then((res) => {
              this.items = res.result.hdetail.data[0]
              this.if_iframe()
            });
        });
    },
    if_iframe(){
      if (this.items.is_iframe) {
        console.log(this.items.is_iframe);
        this.if_Iframe = false
      }else{
        console.log(this.items.is_iframe);
        this.if_Iframe = true

      }
      
    },
    DB_bu() {
      let ID = this.id
      let url = window.location.host
      var aux = document.createElement("input");
      aux.setAttribute("value", "https://workdata.yijianbox.com/#/detail?id=" + ID);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({
        message: '复制分享链接成功',
        type: 'success'
      });
      // this.$message('');
      // alert("");
    },
    toPage(url) {
      window.open(url);
    }
  },
};
</script>

<style scoped>
.detail-container {
  width: 100%;
}
#tag1{
  margin-left: 0px;
}
.detail_tag{
  color:grey; font-size: 1.2em; margin: 10px; display: inline-block;font-size: 12px;
}
#link2{
  background-color: #ffffff;
  color: black;
  border: 1px solid;
  margin-left: 20px;
}
#desc{
  font-size: 1.2em;
}
.navbar-brand{
  font-weight:900
}
.col-md-2{
  height: 2px;display: flex;
  /* 主轴居中 */
  justify-content: center;
  /* 从轴居中 */
  align-items: center;
  margin-top: 100px;
}
#head{
  border-radius:8px;
}
</style>